@import 'src/design-system/styles/typography.mixins';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25%;
}

.centerWrapperPosition {
  justify-content: center;
  margin-top: 0;
}

.title {
  @include font-style-subtitle;
  margin: 0;
  color: var(--text-10);
}

.description {
  @include font-style-default;
  margin-bottom: 24px;
  color: var(--text-30);
  max-width: 420px;
  text-align: center;
}
